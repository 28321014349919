/* Built In Imports */
import { useRouter } from 'next/router';
import React, { createRef, useEffect, useRef, useState } from 'react';

/* External Imports */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  useDisclosure
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import parser from 'html-react-parser';
import filter from 'lodash/filter';
import { nanoid } from 'nanoid';
import ReCAPTCHA from 'react-google-recaptcha';

/* Internal Imports */
/* Components */
import config from '@config';
import CountriesList from '@constants/CountriesList';

/* Services */
import { addHomeSubscribeData } from 'services/commonService';

/**
 * Renders the Subscribe Box component
 *
 * @param newsletters.newsletters
 * @param {object} newsletters
 * @param {string} type
 * @param {string} inputBackground
 * @param {string} submitText
 * @param {boolean} hideSpamText Want to show Spam text or not
 * @returns {ReactElement} Subscribe Box component.
 */

const SubscribeBoxHome = ({
  newsletters,
  type,
  inputBackground,
  submitText,
  hideSpamObj,
  hideSpamText,
  contentObj,
  cType
}) => {
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);
  const [curRegion, setCurRegion] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const emailRegex = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,15}$/i;
  const nameRegex = /^([a-zA-Z ])+$/u;
  const [messageTemp, setMessageTemp] = useState('');
  const countries = CountriesList;
  const recaptchaRef = createRef();
  const lngList = config.lngList;
  const ref = useRef(null);

  React.memo(countries);
  let regionLangMapping = '';
  if(cType === 'home') { 
    regionLangMapping = contentObj?.sectionContent[0]?.content?.json?.regionalLanguages;
  } else {
    regionLangMapping = contentObj?.content?.json?.regionalLanguages;
  }
  // console.log('SubscribeBoxHome contentObj', contentObj, regionLangMapping);

  useEffect(() => {
    if (!isOpen && window?.loc?.indexOf('popup=1') > -1) {
      const parentUrl =
        location.origin +
        location.pathname +
        location.search.replace(/[\?&]popup=1/, '').replace(/^&/, '?');
      window.history.pushState(window.history.state, '', parentUrl);
    }
    recaptchaRef.current.reset();
  }, [isOpen]);

  useEffect(() => {
    window.onpopstate = e => {
      // consoleLog('onpopstate', router, window, e);
      if (window?.loc?.indexOf('popup=1') > -1) {
        // consoleLog('onpopstate return', router.asPath);
        onClose();
        window['loc'] = router.asPath;
      }
    };
  }, [router.asPath]);
  /**
   *
   * @param value
   */
  function validateName(value) {
    let error;
    if (!value) error = 'Name is required';
    if (value.length < 3) error = 'Name is invalid';
    if (value.length > 100) error = 'Name is invalid';
    if (!value.match(nameRegex)) error = 'Full Name accepts only alphabets';
    return error;
  }

  /**
   *
   * @param value
   */
  function validateEmail(value) {
    let error;
    if (!value) error = 'Email is required';
    if (!value.match(emailRegex)) error = 'Invalid Email';
    return error;
  }

  /**
   *
   * @param value
   */
  function validateCountry(value) {
    let error;
    if (!value) error = 'Select Country';
    return error;
  }

  /**
   *
   * @param value
   */
  function validateLang(value) {
    let error;
    if (!value) error = 'Select Language';
    return error;
  }

  /**
   *
   * @param value
   */
  function validateReCaptcha(value) {
    let error;
    if (!value) error = 'Please verify you are human!';
    return error;
  }

  return (
    <>
      <Flex
        w={{ base: '', sm: '', md: '' }}
        flexFlow={{ base: 'column-reverse', md: 'row' }}
        flexWrap="wrap"
        pos="relative"
        margin={{ base: '40px 0 0', md: '25px 20px 0' }}
        justifyContent="center"
        padding="0 0 20px 0"
      >
        <Formik
          initialValues={{
            name: '',
            email: '',
            country: '',
            language: '',
            recaptcha: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const { name, email, country, language } = values;
            setLoading(true);
            const data = addHomeSubscribeData({
              name,
              email,
              country,
              language,
              newsletters,
              type,
              source: `${config.cdnPath}${router.asPath}`,
            });

            data.then(formResponse => {
              resetForm();
              if (typeof formResponse === 'string') {
                setMessageTemp(
                  `<div>We are not able to process your request right now.</div>`
                );
              } else if (
                formResponse[0]?.status_code === 101 ||
                formResponse[0]?.status_code === 102 ||
                formResponse[0]?.status_code === 103 ||
                formResponse[0]?.status_code === 104 ||
                formResponse[0]?.status_code === 105 ||
                formResponse[0]?.status_code === 106
              ) {
                setMessageTemp(formResponse[0]?.message_template);
              } else {
                setMessageTemp(
                  `<div>We are not able to process your request right now.</div>`
                );
              }
              onOpen();
              setLoading(false);
              window.dataLayer.push({
                event: 'newsletter_success',
                source: `${config.cdnPath}${router.asPath}`,
              });
              const popupUrl =
                router.asPath.indexOf('?popup=1') > -1
                  ? router.asPath
                  : router.asPath.indexOf('?') > -1
                    ? `${router.asPath}&popup=1`
                    : `${router.asPath}?popup=1`;
              window.history.pushState(window.history.state, '', popupUrl);
              window['loc'] = popupUrl;
            });
          }}
        >
          {props => (
            <Form>
              <Box
                display={{ base: 'block', xl: 'flex' }}
                m={{ base: '0 5%', lg: 'unset' }}
                justifyContent="space-between"
              >
                <Field name="name" validate={validateName}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                      width={{ base: '100%', xl: 'calc((100% - 40px)/2)' }}
                      m={{ base: '0 0 18px 0', xl: '0' }}
                    >
                      <Input
                        {...field}
                        id="name"
                        variant="outline"
                        isRequired={true}
                        placeholder="Full Name"
                        fontFamily="'FedraSansStd-book', sans-serif"
                        fontSize="18px"
                        color="#8a8173"
                        height={{ base: '50px', lg: '40px' }}
                        padding="10px"
                        borderRadius="3px"
                        border="1px solid #a1a1a1"
                        p="20px"
                        mr="20px"
                        backgroundColor={inputBackground}
                      />
                      <FormErrorMessage justifyContent="center">
                        {form.errors.name}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email" validate={validateEmail}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                      width={{ base: '100%', xl: 'calc((100% - 40px)/2)' }}
                      m={{ base: '0 0 18px 0', xl: '0' }}
                    >
                      <Input
                        {...field}
                        id="email"
                        variant="outline"
                        isRequired={true}
                        placeholder="Email Address"
                        fontFamily="'FedraSansStd-book', sans-serif"
                        fontSize="18px"
                        color="#8a8173"
                        height={{ base: '50px', lg: '40px' }}
                        padding="10px"
                        borderRadius="3px"
                        border="1px solid #a1a1a1"
                        p="20px"
                        type="email"
                        backgroundColor={inputBackground}
                      />
                      <FormErrorMessage justifyContent="center">
                        {form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Box
                display={{ base: 'block', xl: 'flex' }}
                m={{ base: '20px 5%', lg: '20px 0' }}
                justifyContent="space-between"
              >
                <Field name="country" validate={validateCountry}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.country && form.touched.country}
                      width={{ base: '100%', xl: 'calc((100% - 40px)/2)' }}
                      m={{ base: '0 0 18px 0', lg: '0' }}
                    >
                      <Select
                        key="country"
                        {...field}
                        id="country"
                        width="100%"
                        isRequired={true}
                        placeholder="Country"
                        fontFamily="'FedraSansStd-book', sans-serif"
                        fontSize="18px"
                        color="#8a8173"
                        height={{ base: '50px', lg: '40px' }}
                        borderRadius="3px"
                        border="1px solid #a1a1a1"
                        backgroundColor={inputBackground}
                        // onChange={props.handleChange}
                        onChange={event => {
                          props.handleChange(event);
                          const selected = event.target.value;

                          const countryObj = filter(
                            CountriesList,
                            function (country) {
                              // console.log('country', country.region, `${region}/${language}`, country.region === `${region}/${language}`);
                              return country?.value?.toUpperCase() === selected;
                            }
                          )[0];

                          if (countryObj?.region === 'in/en') {
                            setCurRegion('INRegion');
                          } else if (countryObj?.region === 'us/en') {
                            setCurRegion('USRegion');
                          } else {
                            setCurRegion('Others');
                          }
                        }}
                      >
                        {countries.map(country => {
                          return (
                            <option key={nanoid()} value={country.value}>
                              {country.name}
                            </option>
                          );
                        })}
                      </Select>
                      <FormErrorMessage justifyContent="center">
                        {form.errors.country}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="language" validate={validateLang}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.language && form.touched.language}
                      width={{ base: '100%', xl: 'calc((100% - 40px)/2)' }}
                      m={{ base: '0 0 18px 0', lg: '0' }}
                    >
                      <Select
                        key="language"
                        {...field}
                        id="language"
                        width="100%"
                        isRequired={true}
                        placeholder={'Language'}
                        fontFamily="'FedraSansStd-book', sans-serif"
                        fontSize="18px"
                        color="#8a8173"
                        height={{ base: '50px', lg: '40px' }}
                        borderRadius="3px"
                        border="1px solid #a1a1a1"
                        backgroundColor={inputBackground}
                        disabled={!props.values.country ? true : false}
                      >
                        {regionLangMapping &&
                          regionLangMapping[curRegion]?.length > 0 &&
                          regionLangMapping[curRegion]?.map(reg => {
                            return (
                              <option key={nanoid()} value={reg}>
                                {lngList[reg]}
                              </option>
                            );
                          })}
                      </Select>
                      <FormErrorMessage justifyContent="center">
                        {form.errors.language}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Box
                display="flex"
                ref={ref}
                justifyContent="center"
                mt="15px"
                mr={{ base: 0 }}
              >
                <Field name="recaptcha" validate={validateReCaptcha}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.recaptcha && form.touched.recaptcha
                      }
                      width={{ base: 'auto', xl: 'auto' }}
                      m={{ base: '0 0 18px 0', xl: '0' }}
                    >
                      {config.RECAPTCHA_KEY && (
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={config.RECAPTCHA_KEY}
                          onChange={success => {
                            success === null
                              ? recaptchaRef.current.reset()
                              : props.setFieldValue('recaptcha', success);
                          }}
                          onErrored={error => {
                            props.setFieldValue('recaptcha', '');
                            recaptchaRef.current.reset();
                          }}
                        />
                      )}
                      <FormErrorMessage justifyContent="center">
                        {form.errors.recaptcha}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Box marginTop="20px">
                <Box textAlign="center" mt="20px">
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    fontSize={{
                      base: '18px',
                      lg: '16px',
                    }}
                    border="1px solid #a1a1a1"
                    fontFamily="FedraSansStd-medium,sans-serif"
                    fontWeight="300"
                    _active={{
                      border: 'none',
                      background: '#000054',
                    }}
                    margin="0 auto"
                    className="orange-button"
                    mb="5px"
                    display="block"
                    bgColor="#cf4520"
                    h={'auto'}
                    minH="50px"
                    // minW="200px"
                    textAlign="left"
                    padding={'14px 29px'}
                    borderRadius="5px"
                    color="#faf7f0"
                    textDecoration="none"
                    width={'auto'}
                    _hover={{
                      bg: '#000054',
                    }}
                  >
                    {submitText || 'Subscribe'}
                  </Button>
                </Box>
                {!hideSpamText ||
                  (hideSpamObj && (
                    <Box
                      fontFamily="FedraSansStd-book, sans-serif"
                      fontSize={{ base: '', sm: '', md: '16px', lg: '16px' }}
                      lineHeight="1.56"
                      textAlign="center"
                      color="#171717"
                      padding={{ base: '7px 19px', sm: '7px 19px' }}
                    >
                      {hideSpamObj || 'No Spam. Cancel Anytime.'}
                    </Box>
                  ))}
              </Box>
            </Form>
          )}
        </Formik>
        <style jsx global>
          {`
            ::placeholder {
              color: #a1a1a1;
            }
          `}
        </style>
      </Flex>

      {messageTemp && (
        <Box
          maxW={messageTemp ? { base: '90%', md: '476px' } : 'auto'}
          m="20px auto"
        >
          <Modal
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset="slideInBottom"
            size={'xl'}
            blockScrollOnMount={true}
            closeOnOverlayClick={false}
          // scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent p="30px">
              <ModalCloseButton color="#9F9F9F" mt="1" />
              <ModalBody>{parser(messageTemp || '')}</ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default SubscribeBoxHome;
