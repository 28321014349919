/* Built In Imports */
import { useRouter } from 'next/router';
import React, { createRef, useEffect, useRef, useState } from 'react';

/* External Imports */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  useDisclosure,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import parser from 'html-react-parser';
import forEach from 'lodash/forEach';
import { nanoid } from 'nanoid';
import ReCAPTCHA from 'react-google-recaptcha';

/* Internal Imports */
/* Components */
import config from '@config';
import { CountriesList } from '../../../constants';

/* Services */
import { addHomeSubscribeData } from 'services/commonService';

/**
 * Renders the Subscribe Box component
 *
 * @param {object} newsletters
 * @param {string} type
 * @param {string} inputBackground
 * @param {string} submitText
 * @param {boolean} hideSpamText Want to show Spam text or not
 * @param type
 * @param inputBackground
 * @param submitText
 * @param hideSpamObj
 * @param hideSpamText
 * @returns {ReactElement} Subscribe Box component.
 */

const SubscribeBox = ({
  newsletters,
  type,
  inputBackground,
  submitText,
  hideSpamObj,
  hideSpamText,
}) => {
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);
  const [messageTemp, setMessageTemp] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const { region, language } = router.query;
  const emailRegex = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,15}$/i;
  const nameRegex = /[A-Za-z]/;
  const countries = CountriesList;
  const ref = useRef(null);
  const recaptchaRef = createRef();

  React.memo(countries);

  /**
   *
   * @param value
   */
  function validateName(value) {
    let error;
    if (!value) error = 'Name is required';
    if (!value.match(nameRegex)) error = 'Full Name accepts only alphabets';
    return error;
  }

  /**
   *
   * @param value
   */
  function validateEmail(value) {
    let error;
    if (!value) error = 'Email is required';
    if (!value.match(emailRegex)) error = 'Invalid Email';
    return error;
  }

  /**
   *
   * @param value
   */
  function validateCountry(value) {
    let error;
    if (!value) error = 'Select Country';
    return error;
  }

  /**
   *
   * @param value
   */
  function validateReCaptcha(value) {
    let error;
    if (!value) error = 'Please verify you are human!';
    return error;
  }

  /**
   *
   * @param url
   */
  function getUrlParameter(url) {
    let URLVariables = url?.split('&'),
      parameterName,
      result = {};

    forEach(URLVariables, function (parameter) {
      parameterName = parameter.split('=');

      result = {
        ...result,
        [parameterName[0]]: decodeURIComponent(parameterName[1]),
      };
    });

    // parameterName = URLVariables[i].split('=');

    // result = {
    //   ...result,
    //   [parameterName[0]]: decodeURIComponent(parameterName[1]),
    // };
    return result;
  }

  useEffect(() => {
    if (!isOpen && window?.loc?.indexOf('popup=1') > -1) {
      const parentUrl =
        location.origin +
        location.pathname +
        location.search.replace(/[\?&]popup=1/, '').replace(/^&/, '?');
      window.history.pushState(window.history.state, '', parentUrl);
    }
    recaptchaRef.current.reset();
  }, [isOpen]);

  return (
    <>
      <Flex
        w={{ base: '', sm: '', md: '' }}
        flexFlow={{ base: 'column-reverse', md: 'row' }}
        flexWrap="wrap"
        pos="relative"
        margin={{ base: '40px 0 0', md: '25px 20px 0' }}
        justifyContent="center"
        padding="0 0 20px 0"
      >
        <Formik
          initialValues={{ name: '', email: '', country: '', recaptcha: '' }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const { name, email, country } = values;
            setLoading(true);
            const data = addHomeSubscribeData({
              name,
              email,
              country,
              language: language?.toUpperCase(),
              newsletters,
              type,
              source: `${config.cdnPath}${router.asPath}`,
            });
            data.then(formResponse => {
              if (newsletters === 'EuropeNewsletter') {
                let url, pathURL, paramsURL;
                // consoleLog('response', response);
                if (formResponse.status === 'Success') {
                  // consoleLog('Success response');
                  const index = formResponse?.redirectURL?.indexOf(`/in/en/`);
                  if (index != -1) {
                    url = formResponse.redirectURL.substr(index);
                    pathURL = url.split('?')?.[0];
                    paramsURL = getUrlParameter(url.split('?')?.[1]);
                  } else {
                    pathURL = `/${region}/${language}/subscribe-confirmation`;
                  }
                } else {
                  pathURL = `/${region}/${language}/subscribe-failure`;
                }
                router.push({
                  pathname: pathURL,
                  query: {
                    ...paramsURL,
                  },
                });
              } else {
                resetForm();
                if (typeof formResponse === 'string') {
                  setMessageTemp(
                    `<div>Sorry something went wrong. We are not able to process your request right now.</div>`
                  );
                } else if (
                  formResponse[0]?.status_code === 101 ||
                  formResponse[0]?.status_code === 102 ||
                  formResponse[0]?.status_code === 103 ||
                  formResponse[0]?.status_code === 104 ||
                  formResponse[0]?.status_code === 105 ||
                  formResponse[0]?.status_code === 106
                ) {
                  setMessageTemp(formResponse[0]?.message_template);
                } else {
                  setMessageTemp(
                    `<div>Sorry something went wrong. We are not able to process your request right now.</div>`
                  );
                }
                onOpen();
                setLoading(false);
                window.dataLayer.push({
                  event: 'newsletter_success',
                  source: `${config.cdnPath}${router.asPath}`,
                });
                const popupUrl =
                  router.asPath.indexOf('?popup=1') > -1
                    ? router.asPath
                    : router.asPath.indexOf('?') > -1
                      ? `${router.asPath}&popup=1`
                      : `${router.asPath}?popup=1`;
                window.history.pushState(window.history.state, '', popupUrl);
                window['loc'] = popupUrl;
              }
            });
          }}
        >
          {props => (
            <Form>
              <Box
                display={{ base: 'block', xl: 'flex' }}
                m={{ base: '0 5%', lg: 'unset' }}
                justifyContent="space-between"
              >
                <Field name="name" validate={validateName}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                      width={{ base: '100%', xl: 'calc((100% - 40px)/3)' }}
                      m={{ base: '0 0 18px 0', xl: '0' }}
                    >
                      <Input
                        {...field}
                        id="name"
                        variant="outline"
                        isRequired={true}
                        placeholder="Full Name"
                        fontFamily="'FedraSansStd-book', sans-serif"
                        fontSize="18px"
                        color="#8a8173"
                        height={{ base: '50px', lg: '40px' }}
                        padding="10px"
                        borderRadius="3px"
                        border="1px solid #a1a1a1"
                        p="20px"
                        mr="20px"
                        backgroundColor={inputBackground}
                      />
                      <FormErrorMessage justifyContent="center">
                        {form.errors.name}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email" validate={validateEmail}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                      width={{ base: '100%', xl: 'calc((100% - 40px)/3)' }}
                      m={{ base: '0 0 18px 0', xl: '0' }}
                    >
                      <Input
                        {...field}
                        id="email"
                        variant="outline"
                        isRequired={true}
                        placeholder="Email Address"
                        fontFamily="'FedraSansStd-book', sans-serif"
                        fontSize="18px"
                        color="#8a8173"
                        height={{ base: '50px', lg: '40px' }}
                        padding="10px"
                        borderRadius="3px"
                        border="1px solid #a1a1a1"
                        p="20px"
                        type="email"
                        backgroundColor={inputBackground}
                      />
                      <FormErrorMessage justifyContent="center">
                        {form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="country" validate={validateCountry}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.country && form.touched.country}
                      width={{ base: '100%', xl: 'calc((100% - 40px)/3)' }}
                      m={{ base: '0 0 18px 0', lg: '0' }}
                    >
                      <Select
                        key="country"
                        {...field}
                        id="country"
                        width="100%"
                        isRequired={true}
                        placeholder="Country"
                        fontFamily="'FedraSansStd-book', sans-serif"
                        fontSize="18px"
                        color="#8a8173"
                        height={{ base: '50px', lg: '40px' }}
                        borderRadius="3px"
                        border="1px solid #a1a1a1"
                        backgroundColor={inputBackground}
                      >
                        {countries.map(country => {
                          if (newsletters === 'EuropeNewsletter') {
                            return (
                              country?.region === 'uk/en' && (
                                <option key={nanoid()} value={country.value}>
                                  {country.name}
                                </option>
                              )
                            );
                          } else {
                            return (
                              <option key={nanoid()} value={country.value}>
                                {country.name}
                              </option>
                            );
                          }
                        })}
                      </Select>
                      <FormErrorMessage justifyContent="center">
                        {form.errors.country}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Box
                display="flex"
                ref={ref}
                justifyContent="center"
                mt="15px"
                mr={{ base: 0 }}
              >
                <Field name="recaptcha" validate={validateReCaptcha}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.recaptcha && form.touched.recaptcha
                      }
                      width={{ base: 'auto', xl: 'auto' }}
                      m={{ base: '0 0 18px 0', xl: '0' }}
                    >
                      {config.RECAPTCHA_KEY && (
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={config.RECAPTCHA_KEY}
                          onChange={success => {
                            success === null
                              ? recaptchaRef.current.reset()
                              : props.setFieldValue('recaptcha', success);
                          }}
                          onErrored={error => {
                            props.setFieldValue('recaptcha', '');
                            recaptchaRef.current.reset();
                          }}
                        />
                      )}
                      <FormErrorMessage justifyContent="center">
                        {form.errors.recaptcha}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Box marginTop="20px">
                <Box textAlign="center" mt="20px">
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    fontSize={{
                      base: '18px',
                      lg: '16px',
                    }}
                    border="1px solid #a1a1a1"
                    fontFamily="FedraSansStd-medium,sans-serif"
                    fontWeight="300"
                    _active={{
                      border: 'none',
                      background: '#000054',
                    }}
                    margin="0 auto"
                    className="orange-button"
                    mb="5px"
                    display="block"
                    bgColor="#cf4520"
                    h={'auto'}
                    minH="50px"
                    // minW="200px"
                    textAlign="left"
                    padding={'14px 29px'}
                    borderRadius="5px"
                    color="#faf7f0"
                    textDecoration="none"
                    width={'auto'}
                    _hover={{
                      bg: '#000054',
                    }}
                  >
                    {submitText || 'Subscribe'}
                  </Button>
                </Box>
                {!hideSpamText ||
                  (hideSpamObj && (
                    <Box
                      fontFamily="FedraSansStd-book, sans-serif"
                      fontSize={{ base: '', sm: '', md: '16px', lg: '16px' }}
                      lineHeight="1.56"
                      textAlign="center"
                      color="#171717"
                      padding={{ base: '7px 19px', sm: '7px 19px' }}
                    >
                      {hideSpamObj || 'No Spam. Cancel Anytime.'}
                    </Box>
                  ))}
              </Box>
            </Form>
          )}
        </Formik>
        <style jsx global>{`
          ::placeholder {
            color: #a1a1a1;
          }
        `}</style>
      </Flex>
      {messageTemp && (
        <Box
          maxW={messageTemp ? { base: '90%', md: '476px' } : 'auto'}
          m="20px auto"
        >
          <Modal
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset="slideInBottom"
            size={'xl'}
            blockScrollOnMount={true}
            closeOnOverlayClick={false}
          // scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent p="30px">
              <ModalCloseButton color="#9F9F9F" mt="1" />
              <ModalBody>{parser(messageTemp || '')}</ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default SubscribeBox;
