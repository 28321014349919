/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { Box, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import SubscribeBox from './SubscribeBox';
import SubscribeBoxHome from './SubscribeBoxHome';

/* Services */

/**
 * Renders the Subscribe Content component
 *
 * @param contentSection.section
 * @param contentSection.contentSection
 * @param {object} contentSection
 * @param {string} contentType
 * @param {string} language
 * @param {string} bgColor - Background Color
 * @param contentSection.contentType
 * @param contentSection.language
 * @param contentSection.contentObj
 * @param contentSection.ctaText
 * @param contentSection.bgColor
 * @param contentSection.hideSpamText
 * @param contentSection.pType
 * @param contentSection.region
 * @returns {ReactElement} Subscribe Content component
 */
export default function SubscribeContent({
  contentSection,
  contentType,
  language,
  contentObj,
  ctaText = 'Sign Up',
  bgColor = '',
  hideSpamText,
  pType,
  region,
}) {
  // console.log('from new letter', contentObj);
  const [showSubscribeBox, setShowSubscribeBox] = useState(true);
  const router = useRouter();
  const { client } = router.query;
  // const url = router.asPath;

  let cType = contentType;
  if (pType) {
    if (pType === 'center') {
      bgColor = '';
    }
  }

  if (contentObj?.newsletterIdentifier?.toLowerCase() === 'ishakaattupoo') {
    cType = 'video';
    // bgColor = 'white';
  }

  useEffect(() => {
    if (
      router.asPath.includes('client=sadhguru-app') ||
      client === 'sadhguru-app'
    ) {
      setShowSubscribeBox(false);
    }
  }, [client, router.asPath]);

  let content;
  switch (cType) {
    case 'home':
      content = getHomePageContent({ region, contentObj, cType });
      break;
    case 'article':
    case 'sadhguru-spot':
      content = getArticleContent({ contentSection, language });
      break;
    case 'audio':
    case 'video':
    case 'poem':
      content = getVideoContent({
        contentSection,
        language,
        contentObj,
        ctaText,
        bgColor,
        hideSpamText,
      });
      break;
    // case 'sadhguru-spot':
    //   content = getSadhguruSpotContent({});
    // break;
    case 'quotes':
      content = getQuotesContent({ cType, contentObj, pType });
      break;
    default:
      content = <Box></Box>;
  }
  return showSubscribeBox ? content : null;
}

/**
 *
 * @param language
 */
function getNewsLetter(language) {
  let newsletters;
  switch (language) {
    case 'en':
      newsletters = 'EnglishNewsletter';
      break;
    case 'hi':
      newsletters = 'HindiNewsletter';
      break;
    case 'ta':
      newsletters = 'TamilNewsletter';
      break;
    default:
      newsletters = 'EnglishNewsletter';
      break;
  }
  return newsletters;
}

const getVideoContent = ({
  contentSection,
  language,
  contentObj,
  ctaText,
  bgColor,
  hideSpamText,
}) => {
  return (
    <Box
      className="clear"
      textAlign="center"
      mb="30px"
      backgroundColor={bgColor || 'transparent'}
    >
      <Box
        w={{ base: '', sm: '', md: '', xl: '800px' }}
        marginLeft={{
          base: 0,
        }}
        textAlign="center"
        marginTop="20px"
        position="relative"
        zIndex="2"
        paddingLeft="20px"
        paddingRight="20px"
        m="0 auto"
      >
        <Box
          w={{ base: '', sm: '', md: '', xl: '800px' }}
          marginLeft={{
            base: 0,
          }}
          textAlign="center"
          marginTop="20px"
          position="relative"
          zIndex="2"
          paddingLeft="20px"
          paddingRight="20px"
          mb="15px"
          pt="20px"
        >
          <LazyLoadImageComponent
            alt=""
            title=""
            src={`${config.imgPath}/d/46272/1663651933-horizontal-branch.svg`}
          ></LazyLoadImageComponent>
        </Box>
        <Box>
          <Text
            fontFamily="FedraSansStd-A-medium, sans-serif"
            fontSize={{ base: '', sm: '', md: '40px', lg: '40px' }}
            lineHeight="1.13"
            textAlign="center"
            color="#090909"
          >
            {contentObj?.content?.json?.title || "Don't want to miss anything?"}
          </Text>
        </Box>
        <Box>
          <Text
            fontFamily="FedraSansStd-book"
            fontSize={{ base: '', sm: '', md: '24px', lg: '24px' }}
            lineHeight="1.56"
            textAlign="center"
            color="#171717"
            marginBottom="10px"
            p="0 10px"
            mt="20px"
          >
            {contentObj && contentObj?.content?.json?.subtext}
            {!contentObj &&
              'Get weekly updates on the latest blogs via newsletters right in your mailbox.'}
          </Text>
        </Box>
      </Box>
      <SubscribeBox
        newsletters={
          contentObj?.newsletterIdentifier || getNewsLetter(language)
        }
        type={
          contentSection === 'wisdom' ? 'wisdom-subscribe' : 'blog-subscribe'
        }
        inputBackground="#fff"
        submitText={contentObj?.content?.json?.submitButton || ctaText}
        hideSpamObj={contentObj?.content?.json?.noSpamMessage}
        hideSpamText={hideSpamText}
      />
    </Box>
  );
};

const getArticleContent = ({
  contentSection,
  language,
  contentObj,
  ctaText,
  bgColor,
  hideSpamText,
}) => {
  return (
    <Box
      className="clear"
      textAlign="center"
      backgroundColor={bgColor || '#f5f0e5'}
      w={{ base: '94%', lg: 'unset' }}
      m="0 auto"
      mb="30px"
    >
      <Box
        w={{ base: '', sm: '', md: '', xl: 'unset' }}
        marginLeft={{
          base: 0,
        }}
        textAlign="center"
        marginTop="20px"
        position="relative"
        zIndex="2"
        paddingLeft="20px"
        paddingRight="20px"
        m="0 auto"
      >
        <Box>
          <Text
            fontFamily="FedraSansStd-A-medium, sans-serif"
            fontSize={{ base: '28px', sm: '28px', md: '40px', lg: '28px' }}
            lineHeight="1.13"
            textAlign="center"
            color="#090909"
            pt="20px"
          >
            {contentObj?.content?.json?.title || 'Subscribe'}
          </Text>
        </Box>
        <Box
          fontFamily="FedraSansStd-book"
          fontSize={{ base: '', sm: '', md: '24px', lg: '18px' }}
          lineHeight="1.56"
          textAlign="center"
          color="#171717"
          marginBottom="10px"
          p="0 10px"
          mt="20px"
        >
          {contentObj?.content?.json?.subtext ||
            'Get weekly updates on the latest blogs via newsletters right in your mailbox.'}
        </Box>
      </Box>
      <SubscribeBox
        newsletters={
          contentObj?.newsletterIdentifier || getNewsLetter(language)
        }
        type={
          contentSection === 'wisdom' ? 'wisdom-subscribe' : 'blog-subscribe'
        }
        inputBackground="#fff"
        submitText={
          contentObj?.content?.json?.submitButton || ctaText || 'Sign Up'
        }
        hideSpamObj={contentObj?.content?.json?.noSpamMessage}
        hideSpamText={hideSpamText}
      // submitText="Sign Up"
      />
    </Box>
  );
};

const getQuotesContent = ({
  contentSection,
  language,
  contentObj,
  ctaText,
  bgColor,
  hideSpamText,
  pType,
  cType
}) => {
  return (
    <Box
      className="clear"
      textAlign="center"
      backgroundColor={bgColor || '#f5f0e5'}
      w={{ base: '94%', lg: '100%' }}
      m="0 auto"
      mb={pType != 'dailysubscription' ? '30px' : 0}
    >
      <Box
        w={{
          base: '',
          sm: '',
          md: '',
          xl: pType != 'dailysubscription' ? '800px' : '100%',
        }}
        marginLeft={{
          base: 0,
        }}
        textAlign="center"
        marginTop="20px"
        position="relative"
        zIndex="2"
        paddingLeft="20px"
        paddingRight="20px"
        m="0 auto"
      >
        <Box
          w={{ base: '', sm: '', md: '', xl: '800px' }}
          marginLeft={{
            base: 0,
          }}
          textAlign="center"
          marginTop={pType != 'dailysubscription' ? '20px' : 0}
          position="relative"
          zIndex="2"
          paddingLeft="20px"
          paddingRight="20px"
          mb="15px"
          pt={pType != 'dailysubscription' ? '20px' : 0}
        >
          <LazyLoadImageComponent
            alt=""
            title=""
            src={`${config.imgPath}/d/46272/1663653232-horizontal-branch-blue.svg`}
          />
        </Box>
        <Box>
          <Text
            fontFamily="FedraSansStd-A-medium, sans-serif"
            fontSize={{ base: '', sm: '', md: '40px', lg: '28px' }}
            lineHeight="1.13"
            textAlign="center"
            color="#090909"
          >
            {contentObj?.content?.json?.title || 'Sadhguru Quotes'}
          </Text>
        </Box>
        <Box>
          <Text
            fontFamily="FedraSansStd-book"
            fontSize={{ base: '', sm: '', md: '24px', lg: '18px' }}
            lineHeight="1.25"
            textAlign="center"
            color="#171717"
            marginBottom="10px"
            p="0 10px"
            mt="20px"
          >
            {contentObj && contentObj?.content?.json?.subtext}
            {!contentObj &&
              'Get insightful quotes from Sadhguru daily right in your mailbox.'}
          </Text>
        </Box>
        <SubscribeBoxHome
          newsletters={contentObj?.newsletterIdentifier || 'DMQ'}
          type="homepage-dmq"
          inputBackground="#fff"
          submitText={
            contentObj?.content?.json?.submitButton || ctaText || 'Sign Up'
          }
          hideSpamObj={contentObj?.content?.json?.noSpamMessage}
          hideSpamText={hideSpamText}
          contentObj={contentObj}
          cType={cType}
        />
      </Box>
    </Box>
  );
};

const getHomePageContent = ({
  contentSection,
  language,
  contentObj,
  ctaText,
  bgColor,
  hideSpamText,
  region,
  cType,
}) => {
  let subscribeFormRef = useRef();
  const router = useRouter();

  // Scroll to subscribe section on home page
  useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
      if (router.asPath.includes('sadhguru-quotes-subscription')) {
        subscribeFormRef?.current.scrollIntoView({
          block: 'start',
          inline: 'start',
        });
      }
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <Box
      w={{ base: 'unset', lg: '85%' }}
      m={{ base: 'unset', lg: '0 auto' }}
      id="sadhguru-quotes-subscription"
      ref={subscribeFormRef}
    >
      <Text
        textAlign="center"
        mt="20px"
        position="relative"
        zIndex="2"
        pl="20px"
        pr="20px"
        fontFamily="FedraSansStd-medium"
        fontSize="14px"
        color="#8a8173"
        lineHeight="1.56"
      >
        {contentObj?.content?.json?.subtext ||
          'Receive Sadhguru’s quote every day in your inbox.'}
      </Text>
      <SubscribeBoxHome
        type="homepage-dmq"
        region={region}
        newsletters={contentObj?.newsletterIdentifier || 'SadhguruQuotes'}
        inputBackground="#fff"
        submitText={
          contentObj?.sectionContent[0]?.content?.json?.submitButton || ctaText || 'Subscribe'
        }
        hideSpamObj={contentObj?.content?.json?.noSpamMessage}
        hideSpamText={hideSpamText}
        contentObj={contentObj}
        cType={cType}
      />
    </Box>
  );
};

const getSadhguruSpotContent = ({
  contentSection,
  language,
  contentObj,
  ctaText,
  bgColor,
  hideSpamText,
}) => {
  return (
    <Box
      textAlign="center"
      backgroundColor={bgColor || '#f5f0e5'}
      // w={{ base: '100vw', sm: '100vw', md: '100vw', lg: '100vw' }}
      mb="30px"
      className="clear"
    >
      <Box
        w={{ base: '', sm: '', md: '', xl: '800px' }}
        marginLeft={{
          base: 0,
        }}
        textAlign="center"
        marginTop="20px"
        position="relative"
        zIndex="2"
        paddingLeft="20px"
        paddingRight="20px"
        m="0 auto"
      >
        <Box
          w={{ base: '', sm: '', md: '', xl: '800px' }}
          marginLeft={{
            base: 0,
          }}
          textAlign="center"
          marginTop="20px"
          position="relative"
          zIndex="2"
          paddingLeft="20px"
          paddingRight="20px"
          mb="15px"
          pt="20px"
        >
          <LazyLoadImageComponent
            alt=""
            title=""
            src={`${config.imgPath}/d/46272/1663653232-horizontal-branch-blue.svg`}
          />
        </Box>
        <Box>
          <Text
            fontFamily="FedraSansStd-A-medium, sans-serif"
            fontSize={{ base: '', sm: '', md: '40px', lg: '28px' }}
            lineHeight="1.13"
            textAlign="center"
            color="#090909"
          >
            {contentObj?.content?.json?.title ||
              'Sign Up for Monthly Updates from Isha'}
          </Text>
        </Box>
        <Box>
          <Text
            fontFamily="FedraSansStd-book"
            fontSize={{ base: '', sm: '', md: '24px', lg: '18px' }}
            lineHeight="1.25"
            textAlign="center"
            color="#171717"
            marginBottom="10px"
            p="0 10px"
            mt="20px"
          >
            {contentObj && contentObj?.content?.json?.subtext}
            {!contentObj &&
              'Summarized updates from Isha&apos;s monthly Magazine on Isha and Sadhguru available right in your mailbox.'}
          </Text>
        </Box>
      </Box>
      <SubscribeBox
        newsletters={
          contentObj?.newsletterIdentifier || getNewsLetter(language)
        }
        type={contentSection === 'blog-subscribe'}
        // newsletters={contentObj?.newsletterIdentifier || 'SadhguruSpot'}
        // type="homepage-dmq"
        inputBackground="#fff"
        submitText={
          contentObj?.content?.json?.submitButton || ctaText || 'Sign Up'
        }
        hideSpamObj={contentObj?.content?.json?.noSpamMessage}
        hideSpamText={hideSpamText}
      />
    </Box>
  );
};
